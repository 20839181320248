import React, { useRef } from 'react';
import {
  Button,
  IconButton,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Menu,
  MenuItem,
  Typography,
  DialogTitle,
  Dialog,
  DialogActions
} from '@material-ui/core';
import {
  // InfoCard,
  //Header,
  // Page,
  Content,
 // ContentHeader,
  // HeaderLabel,
  // SupportButton,
} from '@backstage/core-components';
import { useStyles } from './styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PageLayout } from 'tmna-ui-kit';
import { rootRouteRef } from '../../routes';
import { useRouteRef, microsoftAuthApiRef, identityApiRef, IdentityApi, configApiRef } from '@backstage/core-plugin-api';
// import { makeStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useAsync } from 'react-use';
// import { useLocation } from 'react-router-dom';
import { costCenterApiRef } from '@internal/plugin-cost-center/src/api';
import { useApi } from '@backstage/core-plugin-api';
import { withStyles } from '@material-ui/core/styles'; //Theme
import Tooltip from '@material-ui/core/Tooltip';
import AuditHistory  from './AuditHistoryComponent';
import NotesTable from './NotesTable';
import { AccountInfoAuditHistory} from '../../types';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { useNavigate } from 'react-router';
import BarChartIcon from '@material-ui/icons/BarChart';
//import { mapChargebacksToTableData } from '../../shared/ChargebacksDetailTable/utils';
import * as XLSX from 'xlsx';
import GetAppIcon from '@material-ui/icons/GetApp';
import DetailsIcon from '@material-ui/icons/Details';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import {
  SupportButton,
  SupportTextList,
  SupportLinks,
  SupportMultipleLinkCard
} from 'tmna-ui-kit';
import VideocamIcon from '@material-ui/icons/Videocam';
import HelpIcon from '@material-ui/icons/Help';

import { alertApiRef } from '@backstage/core-plugin-api';

const FAQ_DOC =
  [
    {
      to: '/docs/default/Component/ACE-FAQ/access-and-onboarding/cloud-account-master/',
      title: 'CAM FAQ',
    },
    {
      to: '/docs/default/Component/ACE-FAQ/Compliance/faq-sox-compliance/',
      title: 'Break Glass FAQ'
    }
  ]

const RELATED_DOCS_SUPPORT_LINKS =
  [
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-1.mp4?csf=1&web=1&e=MTbwV1',
      title: 'How do I view Cloud Accounts and Details?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-2.mp4?csf=1&web=1&e=geu2Nk',
      title: 'How do I view Chargebacks for a given Account?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-3.mp4?csf=1&web=1&e=CD7zHb',
      title:' How do I look up Spend Rate Graph?  ',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-4.mp4?csf=1&web=1&e=7tjcsj',
      title: 'How do I download Cloud Account Information?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-5.mp4?csf=1&web=1&e=CxjSms',
      title:'How do I download Chargeback details?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-6.mp4?csf=1&web=1&e=bl6sbx',
      title:'What bulk operations can users perform?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/CAM-7.mp4?csf=1&web=1&e=2DeQuK',
      title:'How do I edit an account?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/CAM-8.mp4?csf=1&web=1&e=bOcPuQ',
      title:'How do I delete an account?',
    },
    {
      to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/CAM-9.mp4?csf=1&web=1&e=ogoXqq',
      title:'How do I create an account?',
    }
  ];

export const creationDate = (date: string) =>
  date ? moment(date).format('MM/DD/YYYY') : '-';

export const CloudAccountDetails = () => {
  const [copyText, setCopyText] = React.useState('');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [enableBreakGlassRequest, setEnableBreakGlassRequest] = React.useState(false);
  const [enableDelete, setEnableDelete] = React.useState(false);
  const [enableDownload, setEnableDownload] = React.useState(false);
  const authref = useApi(microsoftAuthApiRef);
  const identityApi: IdentityApi = useApi(identityApiRef);
  const [isLoading, setIsLoading] = React.useState(false);
  const alertApi = useApi(alertApiRef);
  const [open, setOpen] = React.useState(false);
  const [rootOrg, setRootOrg] = React.useState('');
  const configApi = useApi(configApiRef);

   const handleClose = (reason:any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsg(false);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const rootLink = useRouteRef(rootRouteRef);
  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Cloud Account Master',
    }),
    [rootLink],
  );
  const [customChargebackDisable, setCustomChargebackDisable] = React.useState(true);
  
  const navigate = useNavigate();
  const costCenterApi = useApi(costCenterApiRef);
  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get('accountId') || '';
  // const location: any = useLocation();
  // const { state: routervalues } = location;
  const {
    value: defaultLoadData = {} as any,
    loading: defaultLoadDataLoading,
   } = useAsync(async () => {
    const token:string = await authref.getIdToken();
    let defaultData;
    if (id) {
      const getRole = await costCenterApi.fetchUserRole(token, id);
      defaultData = await costCenterApi.fetchAccountDetailById(id, token);
      if (defaultData[0]?.root_org === 'TMNA' && defaultData[0]?.account_type === 'Prod' && defaultData[0]?.csp === 'AWS' && defaultData[0]?.status === 'Active') {
        setEnableBreakGlassRequest(true);
      }
      //CDX5229 Cloud Edit
      // if(getRole && getRole.user_role && getRole.user_role === 'GlobalAdmin'){
      //   setEnableEdit(true);  
      // }
      //download enabled only for global admin
      switch (getRole.user_role) {
        case 'RegularUser':
              setEnableEdit(false);
              setEnableDelete(false);
              setEnableDownload(false);
              break;
        case 'Technical': 
              setEnableEdit(true);
              setEnableDelete(false);
              setEnableDownload(false);
              break;
        case 'Owner':
              setEnableEdit(true);
              setEnableDelete(false);
              setEnableDownload(false);
              break;
        case 'Sponsor': //finance analyst??
              setEnableEdit(true);
              setEnableDelete(false);
              setEnableDownload(false);
              break;
        case 'RootAdmin':
              setEnableDownload(true);
              if(defaultData[0]?.root_org===getRole.user_org)
               { 
                 setEnableEdit(true);
                 setEnableDelete(true);
                 //defaultData[0]?.status === 'Active' ? setEnableDelete(true) : setEnableDelete(false);
               }
               else
               {
                 setEnableEdit(false);
                 setEnableDelete(false);
               }
              break;
        case 'GlobalAdmin':
              setEnableEdit(true);
              setEnableDownload(true);
              defaultData[0]?.status === 'Active'?setEnableDelete(true):setEnableDelete(false);
              break;
        default:
              setEnableEdit(false);
              setEnableDownload(false);
              setEnableDelete(false);
      }

    }
    
    
    if (defaultData && defaultData.length > 0) {
      setRootOrg(defaultData[0].root_org);
      if (defaultData[0].root_org==="TMNA") {
        setCustomChargebackDisable(false);
      }
      let newObj: any = {};
      let tech: any = defaultData[0]?.contacts.filter((item: any) => {
        if (item.type === 'Technical') {
          return item.name;
        }
      });
      tech.sort((a:any,b:any) => a.name.localeCompare(b.name))
      let Owner: any = defaultData[0]?.contacts.filter((item: any) => {
        if (item.type === 'Owner') {
          return item.name;
        }
      });
      Owner.sort((a:any,b:any) => a.name.localeCompare(b.name))
      let Sponsor: any = defaultData[0]?.contacts.filter((item: any) => {
        if (item.type === 'Sponsor') {
          return item.name;
        }
      });
      const contactEmails:any[] = []
      Sponsor.forEach((s:any) => {
        contactEmails.push(s.email);
      });
      Owner.forEach((o:any) => {
        contactEmails.push(o.email);
      });
      tech.forEach((t:any) => {
        contactEmails.push(t.email);
      });
      const uniqueEmail = contactEmails.filter((x, i, a) => a.indexOf(x) === i);
      setCopyText(uniqueEmail.join('; '));
      Sponsor.sort((a:any,b:any) => a.name.localeCompare(b.name))
      newObj = { Technical: tech, Owner, Sponsor };
      return { contactCustom: newObj, dataShow: defaultData[0] };
    } else {
      return { contactCustom: '', dataShow: defaultData };
    }
   }, []);

  const redirectToSetting = () => {
       
    navigate('/settings', {
      state: { tabId: "two" },
    });
};
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
   
  const updateStatus=(status:any):string=>{
    if(status==='account_number')
      return 'Account ID';
    if(status==='name'|| status==='account_name')
      return 'Account Name';
    if(status==='status')
      return  'Account Status';
    if(status==='type' || status==='account_type')
      return 'Account Type';
    if(status==='csp')
      return 'Cloud Service Provider';
    if(status==='deployment_model')
      return 'Deployment Model';
    if(status==='root_org')
      return 'Root';
    if(status==='email'|| status==='root_email')
      return 'Root Email ID';
    if(status==='mfa_enabled')
      return 'MFA Enabled';
    if(status==='bu_l2')
      return 'Portfolio';
    if(status==='bu_l3')
      return 'Programs';
    if(status==='app_name')
      return 'Application Name';
    if(status==='app_id')
      return 'Business App ID (Toyota Sys ID)';
    if(status==='company_code')
      return 'Company Code';
    if(status==='gl_id')
      return 'General Ledger ID';
    if(status==='cost_center')
      return 'Cost Center';
    if(status==='wbs_id')
      return 'WBS ID';
    if(status==='monthly_estimate')
      return 'Monthly Spend Estimate';
    if(status==='Owner')
      return 'Account Owner';
    if(status==='Sponsor')
      return 'Financial Sponsor';
    if(status==='Technical')
      return 'Technical Contacts';
    if(status==='org_unit')
      return 'Org Unit';
    if(status==='cldy_status')
      return 'Cloudability Status';

    return status;
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = (msg:string) => {
    if(msg == "yes"){
      setOpen(false);
      SumbitDelete();
    }else{
      setOpen(false);
    }
  };

   const SumbitDelete = async () => 
   {
    const tokenValue: string = await authref.getIdToken();
    const accessTokenValue = await authref.getAccessToken();
    const userName = (await identityApi.getProfileInfo()).displayName;

    let postBody = [
      {
        account_number: id,
        status: 'Deleted',
        saved_by: userName,
      }
    ]

    const params = {
      accountId: id,
      rootOrg: rootOrg,
      isEdit: true,
    };
    
    //CAM edit call for soft delete
    try {
      const camResponse = await costCenterApi.manageCloudAccountMaster(
        params,
        tokenValue,
        accessTokenValue,
        postBody
      );
      if (camResponse?.code == 1) {
        alertApi.post({
          message: camResponse.message,
          severity: 'success',
          display: 'transient',
        });
      } else {
        alertApi.post({
          message: camResponse.message,
          severity: 'error',
          display: 'transient',
        });
      }
    } catch (e) {
      alertApi.post({
        message: 'Something went wrong, please try again!',
        severity: 'error',
        display: 'transient',
      });
    }
  };

  const formatAuditHistory=(data:any):Array<AccountInfoAuditHistory>=>{
     if(data.audits)
     {
     
      let updateData:Array<AccountInfoAuditHistory>=[];
        data.audits.forEach((audit:any)=>{
          audit.change_list.forEach((changelist:any)=>{
            let changedFrom:string="";
            let changedTo:string="";
            if(changelist.attribute_name==='org_unit')
            {
              changedFrom=changelist.old_val?Object.values(changelist.old_val).toString():'';
              changedTo=changelist.new_val?Object.values(changelist.new_val).toString():'';
            }
            else if(changelist.attribute_name==='Owner' || changelist.attribute_name ==="Technical" || changelist.attribute_name=== "Sponsor"){
              changedFrom=changelist.old_val?changelist.old_val.join(", "):'';
              changedTo=changelist.new_val?changelist.new_val.join(", "):'';
            }
            else{
              changedFrom=changelist.old_val?changelist.old_val:'';
              changedTo=changelist.new_val?changelist.new_val:'';
            }

            updateData.push({'modifiedAttributes':updateStatus(changelist.attribute_name),
            'changedFrom':changedFrom,
             'changedTo':changedTo,
             'modifiedBy':audit.event_by,'date':moment(audit.event_date).format('MM.DD.YYYY')});
          })
       })
      return updateData;
     }
     return [];
  }

  const {loading:loadingAuditHistory,error:_auditHistoryError,value:auditHistoryResponse=[]}=useAsync(async()=>{
     if(id)
     {
      const idtoken:string = await authref.getIdToken();
      const response= await costCenterApi.fetchAuditHistoryById(id, idtoken);    
      return formatAuditHistory(response[0]);;
     }
     else{
      return [];
     }
  },[]);
  //**API Call to fetch Audit history response End */

  

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  let USDollar = new Intl.NumberFormat('en-US');

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      //element.scrollIntoView({ behavior: 'smooth' });
      const yOffset = -75; 
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
    if (id == 'headTop') {
      setValue(0);
    }
  };
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
      marginTop: '-5px',
    },
  }))(Tooltip);

  const LightTooltipDownload = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
      marginTop: '8px',
    },
  }))(Tooltip);

  const ref = useRef<HTMLDivElement>(null); 
  
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const formatDataDetailsAccountForExcel = (finalExportDetailData: any) => {
    const excelData: any[] = [];
    finalExportDetailData && finalExportDetailData.map((item: any) => {
      let rowData: any = {};
      rowData['Account ID'] = item['account_number'] === undefined ? '' : item['account_number'];
      rowData['Account Name'] = item['account_name'] === undefined ? '' : item['account_name'];
      rowData['Account Status'] = item['status'] === undefined ? '' : item['status'];
      rowData['Account Type'] = item['account_type'] === undefined ? '' : item['account_type'];
      rowData['CSP'] = item['csp'] === undefined ? '' : item['csp'];
      rowData['Deployment Model'] = item['deployment_model'] === undefined ? '' : item['deployment_model'];
      rowData['Root'] = item['root_org'] === undefined ? '' : item['root_org'];
      rowData['Root Email ID'] = item['root_email'] === undefined ? '' : item['root_email'];
      rowData['MFA Enabled'] = item['mfa_enabled'] === undefined ? '' : item['mfa_enabled'];
      let Sponsor: any = item?.contacts.filter((item1: any) => {
        if (item1.type == 'Sponsor') {
          return item1.name;
        }
      });
      rowData['Financial Sponsor'] = Sponsor && Sponsor.map((u: any) => u.name).join('|');
      let Owner: any = item?.contacts.filter((item1: any) => {
        if (item1.type == 'Owner') {
          return item1.name;
        }
      });
      rowData['Account Owner'] = Owner && Owner.map((u: any) => u.name).join('|');
      let tech: any = item?.contacts.filter((item1: any) => {
        if (item1.type == 'Technical') {
          return item1.name;
        }
      });
      rowData['Technical Contacts'] = tech && tech.map((u: any) => u.name).join('|')

      rowData['Portfolio (BU L2)'] = item['bu_l2'] === undefined ? '' : item['bu_l2'];
      rowData['Program (BU L3)'] = item['bu_l3'] === undefined ? '' : item['bu_l3'];
      rowData['App Name'] = item['app_name'] === undefined ? '' : item['app_name'].split(',').join(' |');
      rowData['Business App ID (Toyota Sys ID)'] = item['app_id'] === undefined ? '' : item['app_id'].split(',').join(' |');
      rowData['Company Code'] = item['company_code'] === undefined ? '' : item['company_code'];
      rowData['GL ID'] = item['gl_id'] === undefined ? '' : item['gl_id'];
      rowData['Cost Center ID'] = item['cost_center'] === undefined ? '' : item['cost_center'];
      rowData['WBS ID'] = item['wbs_id'] === undefined ? '' : item['wbs_id'];
      rowData['Estimated Monthly Spend'] = item['monthly_estimate'] === undefined ? '' : item['monthly_estimate'];
      rowData['Creation Date'] = creationDate(item?.created);
      rowData['Created By'] = item?.created_by ?? '';
      rowData['Last Modified Date'] = creationDate(item?.modified);
      
      excelData.push(rowData);
    });
    return excelData; 
  }

  const handleDownloadExcel = async () => {
    const accountIds: string = id;
    const token:string = await authref.getIdToken();
    const defaultData = await costCenterApi.fetchAccountDetailById(accountIds,token);
    const newArr = formatDataDetailsAccountForExcel(defaultData);
    if (newArr.length > 0) {
      const workSheet = XLSX.utils.json_to_sheet(newArr)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, "CAM")
      XLSX.write(workBook, { bookType: "csv", type: "binary" })
      const currDate = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      const newDate = currDate.replaceAll('/','')
      const currTime = new Date().toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      const time = currTime.replace(':','')
      const customName = `CAM_Details_${newDate}${time}.xlsx`;
      XLSX.writeFile(workBook, customName)
    }
  };
  /** End: Code to download Excel */

      React.useEffect(() => {
        const checkIfClickedOutside = (e: { target: any }) => {
          if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
            setIsMenuOpen(false);
            setAnchorEl(null);
          }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
    
        return () => {
          document.removeEventListener('mousedown', checkIfClickedOutside);
        };
      }, [isMenuOpen]);
    /*
    * Method :redirectToChargeback
    *
    */
    const redirectToChargeback = (type: string) => {
      customChargebackDisable ? '' : navigate('/cloud-account/CloudChargeBackDetails', {
        state: { accountIds: [defaultLoadData.dataShow], type: type  },
      });
    };
    
  const directToBreakGlassForm = async () => {
    setIsLoading(true);
    const token: string = await authref.getIdToken();
    const getRole = await costCenterApi.fetchUserRole(token, id);
    // getRole.user_role = 'GlobalAdmin'; //REMOVE THIS BEFORE DEPLOYING
    console.log(getRole);
    if(getRole.user_role === 'Owner' || getRole.user_role  === 'Technical' || getRole.user_role  === 'GlobalAdmin' || getRole.user_role  === 'RootAdmin'){
      navigate('/cloud-account/RequestBreakGlassAccess', {
        state: { accountId: id },
      });
    }
    else {
      const token: string = await authref.getIdToken();
      const authorizedContacts = await costCenterApi.fetchBreakGlassContactsById(id, token);
      const finalContacts = Array.from(new Set(authorizedContacts));
      alertApi.post({
        message: `Action Denied: ${finalContacts} are the only users authorized to Break Glass on this account.`,
        severity: 'error',
        display: 'permanent'
      });
    }
    setIsLoading(false);
  }


  // Redirect to Edt
  const redirectToEdit = () => {
    navigate('/cloud-account/edit', {
      state: { accountId: id },
    });
  };

  // const HtmlTooltip = withStyles((theme: Theme) => ({
  //   tooltip: {
  //     backgroundColor: '',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //   },
  // }))(Tooltip);
  // const displayFinancialSponser = (data: any) => {
  //   const firstfourAccounts: Array<any> = data?.slice(0, 5);
  //   const allAccounts: string = data && data.map((u: any) => u.name).join(', ');
  //   const returnData =
  //     data && data.length < 6 ? (
  //       data &&
  //       data.map((item: any, index: any) => {
  //         return (
  //           <span
  //             key={index}
  //             style={{
  //               fontWeight: 'initial',
  //               fontSize: '14px',
  //               display: 'block',
  //               width: '224px',
  //               whiteSpace: 'nowrap',
  //               overflow: 'hidden',
  //               textOverflow: 'ellipsis',
  //             }}
  //           >
  //             {item.name} <br />
  //           </span>
  //         );
  //       })
  //     ) : (
  //       <HtmlTooltip title={<>{allAccounts}</>}>
  //         <div>
  //           {firstfourAccounts?.map((item, index) => {
  //             return (
  //               <span
  //                 key={index}
  //                 style={{
  //                   fontWeight: 'initial',
  //                   fontSize: '14px',
  //                   display: 'block',
  //                   width: '224px',
  //                   whiteSpace: 'nowrap',
  //                   overflow: 'hidden',
  //                   textOverflow: 'ellipsis',
  //                 }}
  //               >
  //                 {item.name} {index === 4 ? '...' : ''} <br />
  //               </span>
  //             );
  //           })}
  //         </div>
  //       </HtmlTooltip>
  //     );
  //   return returnData;
  // };
  const FunnelIcon = () => {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.00018 2.5H13.0002L7.99018 8.8L3.00018 2.5ZM0.250178 2.11C2.27018 4.7 6.00018 9.5 6.00018 9.5V15.5C6.00018 16.05 6.45018 16.5 7.00018 16.5H9.00018C9.55018 16.5 10.0002 16.05 10.0002 15.5V9.5C10.0002 9.5 13.7202 4.7 15.7402 2.11C16.2502 1.45 15.7802 0.5 14.9502 0.5H1.04018C0.210178 0.5 -0.259822 1.45 0.250178 2.11Z"
          fill="rgba(0, 0, 0, 0.26)"
        />
      </svg>
    );
  };
  return (
    <PageLayout
      title="Cloud Accounts"
      subtitle=""
      headerBackgroundImg={'/assets/service-catalog_bg.png'}
      backToLink={backToLink}
      displayButton={true}
    >
      <Grid>

      </Grid>
      <Paper className={classes.root} data-testid="tabsHeader" style={{display:'flex',padding: "0px 24px",
    justifyContent: "space-between"}}>
        <div style={{width: "100%"}}>
          <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="secondary"
          centered
        >
          <Tab
            label="Account Information"
            data-testid="AccountInformationTab"
            onClick={() => handleClickScroll('AccountInformation')}
          />
          <Tab
            label="Contact Details"
            data-testid="ContactDetailsTab"
            onClick={() => handleClickScroll('ContactDetails')}
          />
          <Tab
            label="Application Details"
            data-testid="ApplicationDetailsTab"
            onClick={() => handleClickScroll('ApplicationDetails')}
          />
          <Tab
            label="Financial Information"
            data-testid="FinancialInformationTab"
            onClick={() => handleClickScroll('FinancialInformation')}
          />
          <Tab
            label="Audit History"
            data-testid="AuditHistoryTab"
            onClick={() => handleClickScroll('AuditHistory')}
          />
          <LightTooltip title="Scroll to Top"><ExpandLessIcon
            style={{
              marginTop: '15px',
              width: '40px',
              height: '40px',
              color: 'red',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            data-testid="headTopTab"
            onClick={() => handleClickScroll('headTop')}
          ></ExpandLessIcon>
          </LightTooltip>
        </Tabs>
        </div>
        <div style={{alignItems: "center", display: "flex"}}>
              { enableEdit ?
                <Button variant="contained" color="primary" type="submit" onClick={redirectToEdit} >
                  Edit
                </Button> : null
              }
              {console.log(enableDelete)}
              { false ?
                <Button variant="contained" type="submit" onClick={handleClickOpen} className={classes.delButton}>
                  Delete
                </Button> : null
              }
          </div>
        <Dialog
                open={open}
                onClose={()=>handleDelete("no")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
                <DialogActions>
                  <Button onClick={()=>handleDelete("no")} color="primary">
                    No
                  </Button>
                  <Button onClick={()=>handleDelete("yes")} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
          </Dialog>

      </Paper>

      <div style={{ position: 'relative', zIndex: 1 }} id="headTop">
        <span id="AccountInformation"></span>
        <Content>
          {/* <ContentHeader title="Account Details" > */}
          <div className={classes.header}>
            <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Account Details</span>   
            <div style={{display:'flex',alignItems:'center'}}>
                    <div className={classes.parentSupport}><SupportButton tooltipTitle="Support" supportIcon="cloud">
                    <SupportTextList>
                      <Typography variant="body2">
                        Cloud Account Master (CAM) is the list of all cloud accounts in TMNA, where key information about
                        the account is actively managed throughout its lifecycle.
                      </Typography>
                    </SupportTextList>

                    <SupportLinks>
                      <SupportMultipleLinkCard
                        icon={<HelpIcon />}
                        title="Related Links"
                        items={FAQ_DOC}
                      />
                    </SupportLinks>
                   
                    <SupportLinks>
                      <SupportMultipleLinkCard
                        icon={<VideocamIcon />}
                        title="Related Videos"
                        items={RELATED_DOCS_SUPPORT_LINKS}
                      />
                    </SupportLinks>
                  </SupportButton></div>
                  <LightTooltip title="CAM Details">
                    <IconButton className={classes.bulkIcon} aria-label="cost">
                      <DetailsIcon
                          aria-label="View"
                          data-testid="camDetails"
                          style={{ cursor: 'pointer' }}
                          color='disabled'
                        />
                    </IconButton>
                  </LightTooltip>

                  <LightTooltip title="Request Break Glass">
                    <IconButton className={classes.bulkIcon} aria-label="cost" disabled={!enableBreakGlassRequest} style={{ display: configApi.getOptionalString('app.breakglass') === "true" ? 'block' : 'none' }}>
                      <LockOpenIcon
                        aria-label="View"
                        data-testid="RequestBreakGlassRole"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          directToBreakGlassForm() 
                          // alertApi.post({
                          //   message: 'This feature is currently disabled',
                          //   severity: 'error',
                          //   display: 'transient',
                          // });
                        }}
                      />
                    </IconButton>
                  </LightTooltip>

                  <LightTooltip title="Chargeback">
                    <IconButton className={classes.bulkIcon} aria-label="cost" >
                      <MonetizationOnOutlinedIcon
                        data-testid="ChargeBackButton"
                        onClick={() => {redirectToChargeback('ChargeBackButton')}}
                        color={customChargebackDisable ? 'disabled' : 'inherit'}
                      />
                    </IconButton>
                  </LightTooltip>
                  
                  <LightTooltip title="Spend Rate Graph">
                    <IconButton className={classes.bulkIcon} aria-label="Spend Rate Graph" >
                      <BarChartIcon
                        data-testid="SpendRateButton"
                        onClick={() => {redirectToChargeback('SpendRateButton')}}
                        color={customChargebackDisable ? 'disabled' : 'inherit'}
                      />
                    </IconButton>
                  </LightTooltip>
                
                  <LightTooltip title="View Real-Time Cost & Usage in Cloudability">
                    <IconButton className={classes.bulkIcon} aria-label="cost">
                      <a
                       href='https://app.apptio.com/cloudability'
                      target='_blank'
                      style={{ height: "23px",width: "23px"}}
                      >
                        <img
                        src='/assets/apptio.png'
                        style={{ width: "100%",height:'100%' }}
                      />
                      </a>
                    </IconButton>
                  </LightTooltip>
               {enableDownload && <>
                <IconButton className={classes.downloadIcon} aria-label="Download" onClick={handleClick}>
                  <LightTooltipDownload title="Download">
                  <GetAppIcon
                    aria-label="View"
                    data-testid="downloadButton"
                    style={{ cursor: 'pointer' }}
                  /></LightTooltipDownload>
        
                </IconButton>
                <div>
                  <Paper>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose2}
                    >

                      <MenuItem onClick={handleDownloadExcel}>Download CAM details</MenuItem>
                    </Menu>
                  </Paper>
                </div>
                </>}
              
                  <LightTooltip title="Filters">
                    <IconButton  aria-label="Filter">
                      <FunnelIcon  />
                    </IconButton>
                  </LightTooltip>
          
                <LightTooltip title="Configure Defaults">
                  <IconButton
                    aria-label="Configure Defaults"
                    style={{ color: 'red' ,marginTop:'-4px'}}
                    onClick={redirectToSetting}
                    >
                    <SettingsIcon />
                  </IconButton>
                </LightTooltip>
          </div>
          </div>
            {/* <Button variant="contained" disabled>
              Delete
            </Button>
            <Button variant="contained" color="primary">
              Edit
            </Button> */}
          {/* </ContentHeader> */}

          <Card className={classes.root}>
            {(isLoading || defaultLoadDataLoading) && (
              <div className={classes.spinner}>
                <CircularProgress color="secondary" />
              </div>
            )}
            {isLoading === false && defaultLoadDataLoading === false && (
              <CardContent>
                {/* <ContentHeader title="Account Information" ></ContentHeader> */}
                <div className={classes.header}> <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Account Information</span>   </div>
                <Grid item className={classes.gridDivider} >
                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                  />
                </Grid>
                <Grid className={classes.grid} data-testid="accountInformationCard" container spacing={3}>
                  <Grid
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                    className={classes.gridItem}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Account ID:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.account_number}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Account Type:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.account_type}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>Root:</h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.root_org}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Last Modified Date:
                      </h4>
                      <div className={classes.dataLabel}>
                        {creationDate(defaultLoadData?.dataShow?.modified)}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                    className={classes.gridItem}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Account Name:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.account_name}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Cloud Service Provider:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.csp}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        MFA enabled:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.mfa_enabled}
                      </div>
                    </div>
                    {enableDownload && <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Created By:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.created_by}
                      </div>
                    </div>}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                    className={classes.gridItem}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Account Status:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.status}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Deployment Model:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.deployment_model}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>Creation Date:</h4>
                      <div className={classes.dataLabel}>
                        {creationDate(defaultLoadData?.dataShow?.created)}
                      </div>
                    </div> 
                    {enableDownload && <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Root Email ID:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.root_email}
                      </div>
                    </div>  }                  
                  </Grid>
                </Grid>
                <span id="ContactDetails"></span>
                {/* <ContentHeader title="Contact Details"></ContentHeader> */}
                <div className={classes.headerCustom}> 
                  <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Contact Details</span> 
                  {
                    copyText ? <IconButton 
                      aria-label="Copy"
                      title="Copy Contact Emails"
                      onClick={() => {
                        navigator.clipboard.writeText(copyText);
                        setOpenMsg(true);
                      }}
                    >
                      <svg style={{ marginTop : '4px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path fill="rgb(217, 59, 59)" d="M180-81q-24 0-42-18t-18-42v-603h60v603h474v60H180Zm120-120q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300Zm0-60h440v-560H300v560Zm0 0v-560 560Z"/></svg>
                    </IconButton> : ''
                  }
                </div>
                <Grid item id="ContactDetails" className={classes.gridDivider}>
                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                  />
                </Grid>
                <Grid className={classes.grid} data-testid="contactDetailsCard"  container spacing={3}>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Financial Sponsor:
                      </h4>
                      <div className={classes.dataLabelContact}>
                        {/* {displayFinancialSponser(
                          defaultLoadData?.contactCustom?.Sponsor,
                        )} */}
                        {defaultLoadData?.contactCustom?.Sponsor && defaultLoadData?.contactCustom?.Sponsor.map((u: any) => u.name).join(', ')}
                       
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Account Owner:
                      </h4>
                      <div className={classes.dataLabelContact}>
                        {/* {displayFinancialSponser(
                          defaultLoadData?.contactCustom?.Owner,
                        )} */}
                        {defaultLoadData?.contactCustom?.Owner && defaultLoadData?.contactCustom?.Owner.map((u: any) => u.name).join(', ')}
                       
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Technical Contacts:
                      </h4>
                      <div className={classes.dataLabelContact}>
                      {defaultLoadData?.contactCustom?.Technical && defaultLoadData?.contactCustom?.Technical.map((u: any) => u.name).join(', ')}
                        {/* {displayFinancialSponser(
                          defaultLoadData?.contactCustom?.Technical,
                        )} */}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <span id="ApplicationDetails"></span>
                {/* <ContentHeader title="Application Details"></ContentHeader> */}
                <div className={classes.header}> <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Application Details</span>   </div>
                <Grid
                  item
                  id="ApplicationDetails"
                  className={classes.gridDivider}
                >
                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                  />
                </Grid>
                <Grid className={classes.grid}  data-testid="applicationDetailsCard"  container  spacing={3}>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Portfolio:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.bu_l2}
                      </div>
                    </div>
                    {/* <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Application Service ID:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.app_id}
                      </div>
                    </div> */}
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Program:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.bu_l3}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    {/* <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Application Name:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.app_name}
                      </div>
                    </div> */} 
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                      Business App ID (Toyota Sys ID):
                      </h4>
                      <div className={classes.dataLabel}>
                      {defaultLoadData?.dataShow?.app_id}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <span id="FinancialInformation"></span>
                <div className={classes.header}> <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Financial Information</span>   </div>
                <Grid
                  item
                  id="FinancialInformation"
                  className={classes.gridDivider}
                >
                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                  />
                </Grid>
                <Grid className={classes.grid} data-testid="financialInformationCard"  container  spacing={3}>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Company Code:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.company_code}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>WBS ID:</h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.wbs_id}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        General Ledger ID:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.gl_id}
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Monthly Spend Estimate ($):
                      </h4>
                      <div className={classes.dataLabel}> 
                      {defaultLoadData?.dataShow?.monthly_estimate ? "$" + USDollar.format(Number(defaultLoadData?.dataShow?.monthly_estimate.toString())) : ''}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={4}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Cost Center:
                      </h4>
                      <div className={classes.dataLabel}>
                        {defaultLoadData?.dataShow?.cost_center}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.header}> <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Notes</span>   </div>
                <Grid
                  item
                  id="notes"
                  className={classes.gridDivider}
                >
                  <Divider
                    orientation="horizontal"
                    className={classes.divider}
                  />
                </Grid>
                <Grid className={classes.grid}  container  spacing={3}>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    style={{ flexGrow: 2 }}
                  >
                      <div style={{ marginTop: '30px' }}>
                      <h4 style={{ float: 'left', margin: '0px' }}>
                        Note/Comment:
                      </h4>
                      <div>
                        {defaultLoadData?.dataShow?.comments}
                      </div>
                    </div>
                    {false && <div style={{ marginTop: '30px' }}>
                      <div>
                        <NotesTable rowData={defaultLoadData?.dataShow} isLoading={defaultLoadDataLoading}/>
                      </div>
                    </div>}
                  </Grid>
                </Grid>
                {/*<Grid className={classes.grid} data-testid="notes"  container  spacing={3}>
                   <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                    style={{ flexGrow: 2 }}
                  >
                    <div style={{ marginTop: '30px' }}>
                      <div>
                        {false && <NotesTable rowData={defaultLoadData?.dataShow} isLoading={defaultLoadDataLoading}/>}
                      </div>
                    </div>
                  </Grid>
                  
                </Grid> */}
                <span id="AuditHistory"></span>
                {/* <ContentHeader title="Audit History"></ContentHeader> */}
                <div className={classes.header}> <span style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '2.5'}}>Audit History</span>   </div>
                <Grid item className={classes.gridDivider}>
                  <Divider orientation="horizontal" className={classes.divider} />
                </Grid>
                <div style={{marginTop:"1%"}} data-testid="auditHistory" >
                 <AuditHistory rowData={auditHistoryResponse} isLoading={loadingAuditHistory}/>
                </div>
                </CardContent>
            )}
          </Card>
          {/* <Grid item style={{ width: '100%' }}>
            <CloudAccountFetchComponent
              navigateToChargeback={navigateToChargeback}
            />
          </Grid> */}
        </Content>
      </div>
      {/* </Page> */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} className={classes.alerts} open={openMsg} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={copyText ? 'info':'warning'}>
            <div className={classes.alertHeader}>
              {copyText? 'Contact Information Copied':'No Contact Information Available'}
            </div>
          </Alert>
        </Snackbar>
    </PageLayout>
    
  );
};