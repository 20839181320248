import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import {
  InfoCardVariants,
  Link,
  TableOptions,
} from '@backstage/core-components';
import React, { useState, useEffect, FC, Fragment, useCallback } from 'react';
import {
  asComponentEntities,
  componentEntityColumns,
  RelatedEntitiesCard,
} from '../RelatedEntitiesCard';
import {
  MenuItem,
  Menu,
  CircularProgress,
  CircularProgressProps,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CustomAlertDisplay, CustomAlertMessage } from './CustomAlertDisplay';
import { LinkRepoDialog, UnlinkRepoDialog } from './AssociateRepoDialog';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { appCatalogApiRef, RepoData } from '../../api';

const useStyles = makeStyles(theme => ({
  table: {
    position: 'relative',
    '& .MuiSelect-select': {
      padding: '8px 24px 8px 0px !important',
    },
    '& .MuiToolbar-regular': {
      padding: '0px 45px 0px 23px !important',
    },
    '& .MuiTable-root': {
      '& .MuiTableHead-root , .MuiTableBody-root': {
        '& > .MuiTableRow-root .MuiTableCell-root': {
          padding: '10px 12px !important',
          '&:first-child': {
            padding: '10px 12px 10px 24px !important',
          },
          '&:last-child': {
            padding: '10px 24px 10px 12px !important',
          },
        },
      },
    },
  },
  tableButton: {
    position: 'absolute',
    top: theme.spacing(1.25),
    right: theme.spacing(1.25),
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
  },
  dialog: {
    minWidth: '600px',
  },
  link: {
    color: 'navy',
    textDecoration: 'underline',
  },
}));

/** @public */
export interface HasSubcomponentsCardProps {
  variant?: InfoCardVariants;
  tableOptions?: TableOptions;
  title?: string;
}

export const FaqLink = () => (
  <Link to="https://chofer.cloud.toyota.com/docs/default/Component/ACE-FAQ/access-and-onboarding/github-onboarding/#q-how-do-i-find-out-who-can-add-me-to-a-github-team">
    FAQ
  </Link>
);

export const LoadingButton: FC<ButtonProps & {
  loading?: boolean;
  circularProgressProps?: CircularProgressProps;
}> = ({ loading = false, children, circularProgressProps, ...otherProps }) => (
  <Button disabled={loading} {...otherProps}>
    {loading ? (
      <CircularProgress
        size={24}
        color="secondary"
        {...circularProgressProps}
      />
    ) : (
      children
    )}
  </Button>
);

export function HasSubcomponentsCard(props: HasSubcomponentsCardProps) {
  const classes = useStyles();
  const { entity } = useEntity();
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isTypeApp = isComponent && entity.spec?.type === 'application';
  const [associatedRepos, setAssociatedRepos] = useState<{
    loading: boolean;
    data: RepoData[];
  }>({ loading: false, data: [] });
  const appCatalogApi = useApi(appCatalogApiRef);

  const [isOpen, setIsOpen] = useState<'repo' | 'cloud' | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [repoConfirmDialog, setRepoConfirmDialog] = useState(false);
  const [repoDeleteData, setRepoDeleteData] = useState<Entity['metadata']>(
    {} as Entity['metadata'],
  );
  const [messages, setMessages] = useState<Array<CustomAlertMessage>>([]);
  const showAssociate = entity?.spec?.type === 'application';

  const {
    variant = 'gridItem',
    tableOptions = { pageSize: 10, paging: true, search: true },
    title = 'Has Subcomponents',
  } = props;

  const getAssociatedRepos = useCallback(async () => {
    setAssociatedRepos(prevState => ({ ...prevState, loading: true }));
    try {
      const data = await appCatalogApi.getAssociatedRepos(
        entity.metadata?.toyotaSysId as string,
      );
      setAssociatedRepos(prevState => ({ ...prevState, data }));
    } catch (error) {
      /* empty */
    } finally {
      setAssociatedRepos(prevState => ({ ...prevState, loading: false }));
    }
  }, [appCatalogApi, entity.metadata?.toyotaSysId]);

  const handleButtonClick = (type: 'repo' | 'cloud') => {
    setIsOpen(type);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const triggerAlert = (alert: CustomAlertMessage) =>
    setMessages(alerts => alerts.concat(alert));

  const handleRepoConfirmClose = () => {
    setRepoDeleteData({} as RepoData);
    setRepoConfirmDialog(false);
  };

  const handleDeleteConfirmation = async (repo: Entity['metadata']) => {
    setRepoDeleteData(repo);
    setRepoConfirmDialog(true);
  };

  const onUnLinkSuccess = async (githubId: string) => {
    setAssociatedRepos(prevState => ({
      ...prevState,
      data: prevState.data.filter(repo => repo.github_id !== githubId),
    }));
  };

  const onLinkSuccess = async (repos: RepoData[]) => {
    setAssociatedRepos(prevState => ({
      ...prevState,
      data: [...prevState.data, ...repos],
    }));
  };

  const columnsWithDelete = [
    ...componentEntityColumns,
    {
      title: 'Actions',
      render: (row: Entity) => (
        <Tooltip title="Remove Association with Application">
          <IconButton
            onClick={() => handleDeleteConfirmation(row.metadata)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const asRenderAbleEntities = useCallback(
    (entities: Entity[]) => {
      const componentEntities = asComponentEntities(entities);
      return componentEntities.filter(({ metadata }) =>
        associatedRepos.data.some(
          ({ github_id }) => github_id === metadata?.githubId,
        ),
      );
    },
    [associatedRepos.data],
  );

  useEffect(() => {
    if (isTypeApp) getAssociatedRepos();
  }, [getAssociatedRepos, isTypeApp]);

  return (
    <Fragment>
      <div className={classes.table}>
        <RelatedEntitiesCard
          variant={variant}
          title={title}
          entityKind="Component"
          relationType={RELATION_HAS_PART}
          columns={columnsWithDelete}
          asRenderableEntities={asRenderAbleEntities}
          emptyMessage="No subcomponent is part of this component"
          emptyHelpLink="https://backstage.io/docs/features/software-catalog/descriptor-format#specsubcomponentof-optional"
          tableOptions={tableOptions}
          associatedLoading={associatedRepos.loading}
        />
        {showAssociate && (
          <IconButton
            aria-label="View"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.tableButton}
            onClick={handleClick}
            color="inherit"
            children={<LinkIcon />}
          />
        )}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleButtonClick('repo')}>
          Link Repos
        </MenuItem>
        {/* <MenuItem disabled onClick={() => handleButtonClick('cloud')}>
              Link Cloud Accounts
          </MenuItem> */}
      </Menu>

      <LinkRepoDialog
        isOpen={isOpen}
        handleClose={handleClose}
        triggerAlert={triggerAlert}
        associatedRepos={associatedRepos.data}
        onLinkSuccess={onLinkSuccess}
      />
      <UnlinkRepoDialog
        isOpen={repoConfirmDialog}
        repoDeleteData={repoDeleteData}
        handleClose={handleRepoConfirmClose}
        triggerAlert={triggerAlert}
        onUnLinkSuccess={onUnLinkSuccess}
      />

      <CustomAlertDisplay messages={messages} setMessages={setMessages} />
    </Fragment>
  );
}
